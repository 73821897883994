<template>
  <div class="scenario">
    <div class="scenario-bg">
      <h2>助力企业发展，成为企业管理好帮手</h2>
      <p>
        满足企业多种实用场景，
        <br />无论是需求收集，项目跟进、团队会议等各种情况，<br />
        都能满足您的需要，助您高效完成任务
      </p>
    </div>
    <div class="introduce white-wrap">
      <div class="introduce-wrap flex-between-center demand">
        <img src="@/assets/about/需求收集.png" alt="需求收集" />
        <div class="introduce-content">
          <h2>需求收集</h2>
          <p>
            产品经理想要进行需求收集时，可在线创立一个需求收集表格，邀请相关人员进行填写，收集完成后再进行评估确定，同时也可便于回溯问题记录；
          </p>
        </div>
        <img class="icon1" src="@/assets/about/icon-1.png" alt="icon" />
        <img class="icon2" src="@/assets/about/icon-2.png" alt="icon" />
        <img class="icon3" src="@/assets/about/icon-3.png" alt="icon" />
        <img class="icon4" src="@/assets/about/icon-4.png" alt="icon" />
      </div>
    </div>
    <div class="introduce undertint-wrap">
      <div class="introduce-wrap flex-between-center">
        <div class="introduce-content">
          <h2>团队会议</h2>
          <p>
            利用星云文档记录会议内容，会后一键分享给参会人员，其他人员如有补充，也可在线协同修改，方便快捷；
          </p>
        </div>
        <img src="@/assets/about/团队会议.png" alt="团队会议" />
      </div>
    </div>
    <div class="introduce white-wrap">
      <div class="introduce-wrap flex-between-center">
        <img src="@/assets/about/项目跟进.png" alt="项目跟进" />
        <div class="introduce-content">
          <h2>项目跟进</h2>
          <p>
            项目负责人可在线创建一张项目进度表，邀请项目成员加入，项目全员可实时更新及查看最新任务完成情况；
          </p>
        </div>
      </div>
    </div>
        <div class="introduce undertint-wrap">
      <div class="introduce-wrap multiport">
        <div class="introduce-content">
          <h2>移动办公</h2>
          <p>
            星云文档支持多端同步，在PC端修改时，小程序端同步更新，反之亦然，让您不论身在何处，都能随时随地查看和编辑文件；
          </p>
        </div>
        <img class="star-mac" src="@/assets/about/移动办公mac.png" alt="star-mac" />
        <img
          class="star-iPhone"
          src="@/assets/about/移动办公iphone.png"
          alt="star-iPhone"
        />
      </div>
    </div>
    <div class="introduce white-wrap">
      <div class="introduce-wrap flex-between-center">
        <img src="@/assets/about/预算管理.png" alt="预算管理" />
        <div class="introduce-content">
          <h2>预算管理</h2>
          <p>
            财务人员可在线进行协作编辑，避免繁琐的数据汇总及更新；数据实时更新，财务主管可随时掌握预算使用情况，做到心中有数；
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.white-wrap {
  background-color: #ffffff;
}
.undertint-wrap {
  background-color: #f5f7fa;
}
.default-width {
  width: 1200px;
  margin: 0 auto;
}

.scenario-bg {
  width: 100%;
  min-width: 1200px;
  height: 640px;
  background: url(~@/assets/about/scenario-banner.png) no-repeat;
  background-size:100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 42px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #ffffff;
    margin-top: 211px;
    margin-bottom: 16px;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  }
  p {
    width: 506px;
    font-size: 22px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  }
}

.introduce {
  .introduce-wrap {
    height: 720px;
    .default-width();
    img {
      width: 774px;
    }
  }
  .demand {
    position: relative;
    .icon1,
    .icon2,
    .icon3,
    .icon4 {
      position: absolute;
      width: 116px;
      height: 116px;
    }
    .icon1 {
      left: 111px;
      bottom: 108px;
    }
    .icon2 {
      left: 247px;
      bottom: 197px;
    }
    .icon3 {
      left: 384px;
      bottom: 78px;
    }
    .icon4 {
      left: 520px;
      bottom: 121px;
    }
  }
  .multiport {
    display: flex;
    align-items: center;
    position: relative;
    .star-mac {
      position: absolute;
      width: 1045px;
      top: 12px;
      right: -44px;
    }
    .star-iPhone {
      position: absolute;
      width: 317px;
      bottom: 12px;
      right: 11px;
      z-index: 1;
    }
  }
  .introduce-content {
    width: 360px;
    h2 {
      font-size: 42px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #4a61ff;
      margin-block: 32px;
    }
    p {
      font-size: 22px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
